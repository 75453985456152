import _r, { useMemo as N, useRef as me, useReducer as Fr, useEffect as Fe, useCallback as W, forwardRef as Sr, useImperativeHandle as Ir, Fragment as Rr } from "react";
import { g as Cr } from "./_commonjsHelpers-DaMA6jEr.js";
import { r as dr } from "./index-CcXU3z6b.js";
import { c as ee, d as re, b as Xe, e as Qe } from "./tslib.es6-CR4a2p76.js";
var Me = { exports: {} };
/*
object-assign
(c) Sindre Sorhus
@license MIT
*/
var Se, Ve;
function kr() {
  if (Ve) return Se;
  Ve = 1;
  var e = Object.getOwnPropertySymbols, r = Object.prototype.hasOwnProperty, n = Object.prototype.propertyIsEnumerable;
  function t(a) {
    if (a == null)
      throw new TypeError("Object.assign cannot be called with null or undefined");
    return Object(a);
  }
  function o() {
    try {
      if (!Object.assign)
        return !1;
      var a = new String("abc");
      if (a[5] = "de", Object.getOwnPropertyNames(a)[0] === "5")
        return !1;
      for (var l = {}, s = 0; s < 10; s++)
        l["_" + String.fromCharCode(s)] = s;
      var O = Object.getOwnPropertyNames(l).map(function(E) {
        return l[E];
      });
      if (O.join("") !== "0123456789")
        return !1;
      var A = {};
      return "abcdefghijklmnopqrst".split("").forEach(function(E) {
        A[E] = E;
      }), Object.keys(Object.assign({}, A)).join("") === "abcdefghijklmnopqrst";
    } catch {
      return !1;
    }
  }
  return Se = o() ? Object.assign : function(a, l) {
    for (var s, O = t(a), A, E = 1; E < arguments.length; E++) {
      s = Object(arguments[E]);
      for (var w in s)
        r.call(s, w) && (O[w] = s[w]);
      if (e) {
        A = e(s);
        for (var P = 0; P < A.length; P++)
          n.call(s, A[P]) && (O[A[P]] = s[A[P]]);
      }
    }
    return O;
  }, Se;
}
var Ie, Ze;
function Be() {
  if (Ze) return Ie;
  Ze = 1;
  var e = "SECRET_DO_NOT_PASS_THIS_OR_YOU_WILL_BE_FIRED";
  return Ie = e, Ie;
}
var Re, Ne;
function vr() {
  return Ne || (Ne = 1, Re = Function.call.bind(Object.prototype.hasOwnProperty)), Re;
}
var Ce, er;
function zr() {
  if (er) return Ce;
  er = 1;
  var e = function() {
  };
  if (process.env.NODE_ENV !== "production") {
    var r = Be(), n = {}, t = vr();
    e = function(a) {
      var l = "Warning: " + a;
      typeof console < "u" && console.error(l);
      try {
        throw new Error(l);
      } catch {
      }
    };
  }
  function o(a, l, s, O, A) {
    if (process.env.NODE_ENV !== "production") {
      for (var E in a)
        if (t(a, E)) {
          var w;
          try {
            if (typeof a[E] != "function") {
              var P = Error(
                (O || "React class") + ": " + s + " type `" + E + "` is invalid; it must be a function, usually from the `prop-types` package, but received `" + typeof a[E] + "`.This often happens because of typos such as `PropTypes.function` instead of `PropTypes.func`."
              );
              throw P.name = "Invariant Violation", P;
            }
            w = a[E](l, E, O, s, null, r);
          } catch (H) {
            w = H;
          }
          if (w && !(w instanceof Error) && e(
            (O || "React class") + ": type specification of " + s + " `" + E + "` is invalid; the type checker function must return `null` or an `Error` but returned a " + typeof w + ". You may have forgotten to pass an argument to the type checker creator (arrayOf, instanceOf, objectOf, oneOf, oneOfType, and shape all require an argument)."
          ), w instanceof Error && !(w.message in n)) {
            n[w.message] = !0;
            var z = A ? A() : "";
            e(
              "Failed " + s + " type: " + w.message + (z ?? "")
            );
          }
        }
    }
  }
  return o.resetWarningCache = function() {
    process.env.NODE_ENV !== "production" && (n = {});
  }, Ce = o, Ce;
}
var ke, rr;
function Lr() {
  if (rr) return ke;
  rr = 1;
  var e = dr(), r = kr(), n = Be(), t = vr(), o = zr(), a = function() {
  };
  process.env.NODE_ENV !== "production" && (a = function(s) {
    var O = "Warning: " + s;
    typeof console < "u" && console.error(O);
    try {
      throw new Error(O);
    } catch {
    }
  });
  function l() {
    return null;
  }
  return ke = function(s, O) {
    var A = typeof Symbol == "function" && Symbol.iterator, E = "@@iterator";
    function w(i) {
      var c = i && (A && i[A] || i[E]);
      if (typeof c == "function")
        return c;
    }
    var P = "<<anonymous>>", z = {
      array: q("array"),
      bigint: q("bigint"),
      bool: q("boolean"),
      func: q("function"),
      number: q("number"),
      object: q("object"),
      string: q("string"),
      symbol: q("symbol"),
      any: le(),
      arrayOf: ne,
      element: pe(),
      elementType: te(),
      instanceOf: de,
      node: J(),
      objectOf: ie,
      oneOf: B,
      oneOfType: G,
      shape: ae,
      exact: Q
    };
    function H(i, c) {
      return i === c ? i !== 0 || 1 / i === 1 / c : i !== i && c !== c;
    }
    function j(i, c) {
      this.message = i, this.data = c && typeof c == "object" ? c : {}, this.stack = "";
    }
    j.prototype = Error.prototype;
    function C(i) {
      if (process.env.NODE_ENV !== "production")
        var c = {}, v = 0;
      function y(b, d, g, h, D, m, I) {
        if (h = h || P, m = m || g, I !== n) {
          if (O) {
            var _ = new Error(
              "Calling PropTypes validators directly is not supported by the `prop-types` package. Use `PropTypes.checkPropTypes()` to call them. Read more at http://fb.me/use-check-prop-types"
            );
            throw _.name = "Invariant Violation", _;
          } else if (process.env.NODE_ENV !== "production" && typeof console < "u") {
            var U = h + ":" + g;
            !c[U] && // Avoid spamming the console because they are often not actionable except for lib authors
            v < 3 && (a(
              "You are manually calling a React.PropTypes validation function for the `" + m + "` prop on `" + h + "`. This is deprecated and will throw in the standalone `prop-types` package. You may be seeing this warning due to a third-party PropTypes library. See https://fb.me/react-warning-dont-call-proptypes for details."
            ), c[U] = !0, v++);
          }
        }
        return d[g] == null ? b ? d[g] === null ? new j("The " + D + " `" + m + "` is marked as required " + ("in `" + h + "`, but its value is `null`.")) : new j("The " + D + " `" + m + "` is marked as required in " + ("`" + h + "`, but its value is `undefined`.")) : null : i(d, g, h, D, m);
      }
      var p = y.bind(null, !1);
      return p.isRequired = y.bind(null, !0), p;
    }
    function q(i) {
      function c(v, y, p, b, d, g) {
        var h = v[y], D = K(h);
        if (D !== i) {
          var m = Y(h);
          return new j(
            "Invalid " + b + " `" + d + "` of type " + ("`" + m + "` supplied to `" + p + "`, expected ") + ("`" + i + "`."),
            { expectedType: i }
          );
        }
        return null;
      }
      return C(c);
    }
    function le() {
      return C(l);
    }
    function ne(i) {
      function c(v, y, p, b, d) {
        if (typeof i != "function")
          return new j("Property `" + d + "` of component `" + p + "` has invalid PropType notation inside arrayOf.");
        var g = v[y];
        if (!Array.isArray(g)) {
          var h = K(g);
          return new j("Invalid " + b + " `" + d + "` of type " + ("`" + h + "` supplied to `" + p + "`, expected an array."));
        }
        for (var D = 0; D < g.length; D++) {
          var m = i(g, D, p, b, d + "[" + D + "]", n);
          if (m instanceof Error)
            return m;
        }
        return null;
      }
      return C(c);
    }
    function pe() {
      function i(c, v, y, p, b) {
        var d = c[v];
        if (!s(d)) {
          var g = K(d);
          return new j("Invalid " + p + " `" + b + "` of type " + ("`" + g + "` supplied to `" + y + "`, expected a single ReactElement."));
        }
        return null;
      }
      return C(i);
    }
    function te() {
      function i(c, v, y, p, b) {
        var d = c[v];
        if (!e.isValidElementType(d)) {
          var g = K(d);
          return new j("Invalid " + p + " `" + b + "` of type " + ("`" + g + "` supplied to `" + y + "`, expected a single ReactElement type."));
        }
        return null;
      }
      return C(i);
    }
    function de(i) {
      function c(v, y, p, b, d) {
        if (!(v[y] instanceof i)) {
          var g = i.name || P, h = k(v[y]);
          return new j("Invalid " + b + " `" + d + "` of type " + ("`" + h + "` supplied to `" + p + "`, expected ") + ("instance of `" + g + "`."));
        }
        return null;
      }
      return C(c);
    }
    function B(i) {
      if (!Array.isArray(i))
        return process.env.NODE_ENV !== "production" && (arguments.length > 1 ? a(
          "Invalid arguments supplied to oneOf, expected an array, got " + arguments.length + " arguments. A common mistake is to write oneOf(x, y, z) instead of oneOf([x, y, z])."
        ) : a("Invalid argument supplied to oneOf, expected an array.")), l;
      function c(v, y, p, b, d) {
        for (var g = v[y], h = 0; h < i.length; h++)
          if (H(g, i[h]))
            return null;
        var D = JSON.stringify(i, function(I, _) {
          var U = Y(_);
          return U === "symbol" ? String(_) : _;
        });
        return new j("Invalid " + b + " `" + d + "` of value `" + String(g) + "` " + ("supplied to `" + p + "`, expected one of " + D + "."));
      }
      return C(c);
    }
    function ie(i) {
      function c(v, y, p, b, d) {
        if (typeof i != "function")
          return new j("Property `" + d + "` of component `" + p + "` has invalid PropType notation inside objectOf.");
        var g = v[y], h = K(g);
        if (h !== "object")
          return new j("Invalid " + b + " `" + d + "` of type " + ("`" + h + "` supplied to `" + p + "`, expected an object."));
        for (var D in g)
          if (t(g, D)) {
            var m = i(g, D, p, b, d + "." + D, n);
            if (m instanceof Error)
              return m;
          }
        return null;
      }
      return C(c);
    }
    function G(i) {
      if (!Array.isArray(i))
        return process.env.NODE_ENV !== "production" && a("Invalid argument supplied to oneOfType, expected an instance of array."), l;
      for (var c = 0; c < i.length; c++) {
        var v = i[c];
        if (typeof v != "function")
          return a(
            "Invalid argument supplied to oneOfType. Expected an array of check functions, but received " + ue(v) + " at index " + c + "."
          ), l;
      }
      function y(p, b, d, g, h) {
        for (var D = [], m = 0; m < i.length; m++) {
          var I = i[m], _ = I(p, b, d, g, h, n);
          if (_ == null)
            return null;
          _.data && t(_.data, "expectedType") && D.push(_.data.expectedType);
        }
        var U = D.length > 0 ? ", expected one of type [" + D.join(", ") + "]" : "";
        return new j("Invalid " + g + " `" + h + "` supplied to " + ("`" + d + "`" + U + "."));
      }
      return C(y);
    }
    function J() {
      function i(c, v, y, p, b) {
        return F(c[v]) ? null : new j("Invalid " + p + " `" + b + "` supplied to " + ("`" + y + "`, expected a ReactNode."));
      }
      return C(i);
    }
    function oe(i, c, v, y, p) {
      return new j(
        (i || "React class") + ": " + c + " type `" + v + "." + y + "` is invalid; it must be a function, usually from the `prop-types` package, but received `" + p + "`."
      );
    }
    function ae(i) {
      function c(v, y, p, b, d) {
        var g = v[y], h = K(g);
        if (h !== "object")
          return new j("Invalid " + b + " `" + d + "` of type `" + h + "` " + ("supplied to `" + p + "`, expected `object`."));
        for (var D in i) {
          var m = i[D];
          if (typeof m != "function")
            return oe(p, b, d, D, Y(m));
          var I = m(g, D, p, b, d + "." + D, n);
          if (I)
            return I;
        }
        return null;
      }
      return C(c);
    }
    function Q(i) {
      function c(v, y, p, b, d) {
        var g = v[y], h = K(g);
        if (h !== "object")
          return new j("Invalid " + b + " `" + d + "` of type `" + h + "` " + ("supplied to `" + p + "`, expected `object`."));
        var D = r({}, v[y], i);
        for (var m in D) {
          var I = i[m];
          if (t(i, m) && typeof I != "function")
            return oe(p, b, d, m, Y(I));
          if (!I)
            return new j(
              "Invalid " + b + " `" + d + "` key `" + m + "` supplied to `" + p + "`.\nBad object: " + JSON.stringify(v[y], null, "  ") + `
Valid keys: ` + JSON.stringify(Object.keys(i), null, "  ")
            );
          var _ = I(g, m, p, b, d + "." + m, n);
          if (_)
            return _;
        }
        return null;
      }
      return C(c);
    }
    function F(i) {
      switch (typeof i) {
        case "number":
        case "string":
        case "undefined":
          return !0;
        case "boolean":
          return !i;
        case "object":
          if (Array.isArray(i))
            return i.every(F);
          if (i === null || s(i))
            return !0;
          var c = w(i);
          if (c) {
            var v = c.call(i), y;
            if (c !== i.entries) {
              for (; !(y = v.next()).done; )
                if (!F(y.value))
                  return !1;
            } else
              for (; !(y = v.next()).done; ) {
                var p = y.value;
                if (p && !F(p[1]))
                  return !1;
              }
          } else
            return !1;
          return !0;
        default:
          return !1;
      }
    }
    function L(i, c) {
      return i === "symbol" ? !0 : c ? c["@@toStringTag"] === "Symbol" || typeof Symbol == "function" && c instanceof Symbol : !1;
    }
    function K(i) {
      var c = typeof i;
      return Array.isArray(i) ? "array" : i instanceof RegExp ? "object" : L(c, i) ? "symbol" : c;
    }
    function Y(i) {
      if (typeof i > "u" || i === null)
        return "" + i;
      var c = K(i);
      if (c === "object") {
        if (i instanceof Date)
          return "date";
        if (i instanceof RegExp)
          return "regexp";
      }
      return c;
    }
    function ue(i) {
      var c = Y(i);
      switch (c) {
        case "array":
        case "object":
          return "an " + c;
        case "boolean":
        case "date":
        case "regexp":
          return "a " + c;
        default:
          return c;
      }
    }
    function k(i) {
      return !i.constructor || !i.constructor.name ? P : i.constructor.name;
    }
    return z.checkPropTypes = o, z.resetWarningCache = o.resetWarningCache, z.PropTypes = z, z;
  }, ke;
}
var ze, nr;
function Mr() {
  if (nr) return ze;
  nr = 1;
  var e = Be();
  function r() {
  }
  function n() {
  }
  return n.resetWarningCache = r, ze = function() {
    function t(l, s, O, A, E, w) {
      if (w !== e) {
        var P = new Error(
          "Calling PropTypes validators directly is not supported by the `prop-types` package. Use PropTypes.checkPropTypes() to call them. Read more at http://fb.me/use-check-prop-types"
        );
        throw P.name = "Invariant Violation", P;
      }
    }
    t.isRequired = t;
    function o() {
      return t;
    }
    var a = {
      array: t,
      bigint: t,
      bool: t,
      func: t,
      number: t,
      object: t,
      string: t,
      symbol: t,
      any: t,
      arrayOf: o,
      element: t,
      elementType: t,
      instanceOf: o,
      node: t,
      objectOf: o,
      oneOf: o,
      oneOfType: o,
      shape: o,
      exact: o,
      checkPropTypes: n,
      resetWarningCache: r
    };
    return a.PropTypes = a, a;
  }, ze;
}
if (process.env.NODE_ENV !== "production") {
  var Wr = dr(), qr = !0;
  Me.exports = Lr()(Wr.isElement, qr);
} else
  Me.exports = Mr()();
var Kr = Me.exports;
const T = /* @__PURE__ */ Cr(Kr);
var $r = /* @__PURE__ */ new Map([
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  ["aac", "audio/aac"],
  ["abw", "application/x-abiword"],
  ["arc", "application/x-freearc"],
  ["avif", "image/avif"],
  ["avi", "video/x-msvideo"],
  ["azw", "application/vnd.amazon.ebook"],
  ["bin", "application/octet-stream"],
  ["bmp", "image/bmp"],
  ["bz", "application/x-bzip"],
  ["bz2", "application/x-bzip2"],
  ["cda", "application/x-cdf"],
  ["csh", "application/x-csh"],
  ["css", "text/css"],
  ["csv", "text/csv"],
  ["doc", "application/msword"],
  ["docx", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
  ["eot", "application/vnd.ms-fontobject"],
  ["epub", "application/epub+zip"],
  ["gz", "application/gzip"],
  ["gif", "image/gif"],
  ["heic", "image/heic"],
  ["heif", "image/heif"],
  ["htm", "text/html"],
  ["html", "text/html"],
  ["ico", "image/vnd.microsoft.icon"],
  ["ics", "text/calendar"],
  ["jar", "application/java-archive"],
  ["jpeg", "image/jpeg"],
  ["jpg", "image/jpeg"],
  ["js", "text/javascript"],
  ["json", "application/json"],
  ["jsonld", "application/ld+json"],
  ["mid", "audio/midi"],
  ["midi", "audio/midi"],
  ["mjs", "text/javascript"],
  ["mp3", "audio/mpeg"],
  ["mp4", "video/mp4"],
  ["mpeg", "video/mpeg"],
  ["mpkg", "application/vnd.apple.installer+xml"],
  ["odp", "application/vnd.oasis.opendocument.presentation"],
  ["ods", "application/vnd.oasis.opendocument.spreadsheet"],
  ["odt", "application/vnd.oasis.opendocument.text"],
  ["oga", "audio/ogg"],
  ["ogv", "video/ogg"],
  ["ogx", "application/ogg"],
  ["opus", "audio/opus"],
  ["otf", "font/otf"],
  ["png", "image/png"],
  ["pdf", "application/pdf"],
  ["php", "application/x-httpd-php"],
  ["ppt", "application/vnd.ms-powerpoint"],
  ["pptx", "application/vnd.openxmlformats-officedocument.presentationml.presentation"],
  ["rar", "application/vnd.rar"],
  ["rtf", "application/rtf"],
  ["sh", "application/x-sh"],
  ["svg", "image/svg+xml"],
  ["swf", "application/x-shockwave-flash"],
  ["tar", "application/x-tar"],
  ["tif", "image/tiff"],
  ["tiff", "image/tiff"],
  ["ts", "video/mp2t"],
  ["ttf", "font/ttf"],
  ["txt", "text/plain"],
  ["vsd", "application/vnd.visio"],
  ["wav", "audio/wav"],
  ["weba", "audio/webm"],
  ["webm", "video/webm"],
  ["webp", "image/webp"],
  ["woff", "font/woff"],
  ["woff2", "font/woff2"],
  ["xhtml", "application/xhtml+xml"],
  ["xls", "application/vnd.ms-excel"],
  ["xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
  ["xml", "application/xml"],
  ["xul", "application/vnd.mozilla.xul+xml"],
  ["zip", "application/zip"],
  ["7z", "application/x-7z-compressed"],
  // Others
  ["mkv", "video/x-matroska"],
  ["mov", "video/quicktime"],
  ["msg", "application/vnd.ms-outlook"]
]);
function fe(e, r) {
  var n = Hr(e);
  if (typeof n.path != "string") {
    var t = e.webkitRelativePath;
    Object.defineProperty(n, "path", {
      value: typeof r == "string" ? r : typeof t == "string" && t.length > 0 ? t : e.name,
      writable: !1,
      configurable: !1,
      enumerable: !0
    });
  }
  return n;
}
function Hr(e) {
  var r = e.name, n = r && r.lastIndexOf(".") !== -1;
  if (n && !e.type) {
    var t = r.split(".").pop().toLowerCase(), o = $r.get(t);
    o && Object.defineProperty(e, "type", {
      value: o,
      writable: !1,
      configurable: !1,
      enumerable: !0
    });
  }
  return e;
}
var Br = [
  // Thumbnail cache files for macOS and Windows
  ".DS_Store",
  "Thumbs.db"
  // Windows
];
function Ur(e) {
  return ee(this, void 0, void 0, function() {
    return re(this, function(r) {
      return he(e) && Yr(e.dataTransfer) ? [2, Qr(e.dataTransfer, e.type)] : Gr(e) ? [2, Jr(e)] : Array.isArray(e) && e.every(function(n) {
        return "getFile" in n && typeof n.getFile == "function";
      }) ? [2, Xr(e)] : [2, []];
    });
  });
}
function Yr(e) {
  return he(e);
}
function Gr(e) {
  return he(e) && he(e.target);
}
function he(e) {
  return typeof e == "object" && e !== null;
}
function Jr(e) {
  return We(e.target.files).map(function(r) {
    return fe(r);
  });
}
function Xr(e) {
  return ee(this, void 0, void 0, function() {
    var r;
    return re(this, function(n) {
      switch (n.label) {
        case 0:
          return [4, Promise.all(e.map(function(t) {
            return t.getFile();
          }))];
        case 1:
          return r = n.sent(), [2, r.map(function(t) {
            return fe(t);
          })];
      }
    });
  });
}
function Qr(e, r) {
  return ee(this, void 0, void 0, function() {
    var n, t;
    return re(this, function(o) {
      switch (o.label) {
        case 0:
          return e.items ? (n = We(e.items).filter(function(a) {
            return a.kind === "file";
          }), r !== "drop" ? [2, n] : [4, Promise.all(n.map(Vr))]) : [3, 2];
        case 1:
          return t = o.sent(), [2, tr(yr(t))];
        case 2:
          return [2, tr(We(e.files).map(function(a) {
            return fe(a);
          }))];
      }
    });
  });
}
function tr(e) {
  return e.filter(function(r) {
    return Br.indexOf(r.name) === -1;
  });
}
function We(e) {
  if (e === null)
    return [];
  for (var r = [], n = 0; n < e.length; n++) {
    var t = e[n];
    r.push(t);
  }
  return r;
}
function Vr(e) {
  if (typeof e.webkitGetAsEntry != "function")
    return ir(e);
  var r = e.webkitGetAsEntry();
  return r && r.isDirectory ? gr(r) : ir(e);
}
function yr(e) {
  return e.reduce(function(r, n) {
    return Xe(Xe([], Qe(r), !1), Qe(Array.isArray(n) ? yr(n) : [n]), !1);
  }, []);
}
function ir(e) {
  var r = e.getAsFile();
  if (!r)
    return Promise.reject("".concat(e, " is not a File"));
  var n = fe(r);
  return Promise.resolve(n);
}
function Zr(e) {
  return ee(this, void 0, void 0, function() {
    return re(this, function(r) {
      return [2, e.isDirectory ? gr(e) : Nr(e)];
    });
  });
}
function gr(e) {
  var r = e.createReader();
  return new Promise(function(n, t) {
    var o = [];
    function a() {
      var l = this;
      r.readEntries(function(s) {
        return ee(l, void 0, void 0, function() {
          var O, A, E;
          return re(this, function(w) {
            switch (w.label) {
              case 0:
                if (s.length) return [3, 5];
                w.label = 1;
              case 1:
                return w.trys.push([1, 3, , 4]), [4, Promise.all(o)];
              case 2:
                return O = w.sent(), n(O), [3, 4];
              case 3:
                return A = w.sent(), t(A), [3, 4];
              case 4:
                return [3, 6];
              case 5:
                E = Promise.all(s.map(Zr)), o.push(E), a(), w.label = 6;
              case 6:
                return [
                  2
                  /*return*/
                ];
            }
          });
        });
      }, function(s) {
        t(s);
      });
    }
    a();
  });
}
function Nr(e) {
  return ee(this, void 0, void 0, function() {
    return re(this, function(r) {
      return [2, new Promise(function(n, t) {
        e.file(function(o) {
          var a = fe(o, e.fullPath);
          n(a);
        }, function(o) {
          t(o);
        });
      })];
    });
  });
}
var en = function(e, r) {
  if (e && r) {
    var n = Array.isArray(r) ? r : r.split(","), t = e.name || "", o = (e.type || "").toLowerCase(), a = o.replace(/\/.*$/, "");
    return n.some(function(l) {
      var s = l.trim().toLowerCase();
      return s.charAt(0) === "." ? t.toLowerCase().endsWith(s) : s.endsWith("/*") ? a === s.replace(/\/.*$/, "") : o === s;
    });
  }
  return !0;
};
function or(e) {
  return tn(e) || nn(e) || br(e) || rn();
}
function rn() {
  throw new TypeError(`Invalid attempt to spread non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
}
function nn(e) {
  if (typeof Symbol < "u" && e[Symbol.iterator] != null || e["@@iterator"] != null) return Array.from(e);
}
function tn(e) {
  if (Array.isArray(e)) return qe(e);
}
function ar(e, r) {
  var n = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var t = Object.getOwnPropertySymbols(e);
    r && (t = t.filter(function(o) {
      return Object.getOwnPropertyDescriptor(e, o).enumerable;
    })), n.push.apply(n, t);
  }
  return n;
}
function ur(e) {
  for (var r = 1; r < arguments.length; r++) {
    var n = arguments[r] != null ? arguments[r] : {};
    r % 2 ? ar(Object(n), !0).forEach(function(t) {
      mr(e, t, n[t]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(n)) : ar(Object(n)).forEach(function(t) {
      Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(n, t));
    });
  }
  return e;
}
function mr(e, r, n) {
  return r in e ? Object.defineProperty(e, r, { value: n, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = n, e;
}
function se(e, r) {
  return un(e) || an(e, r) || br(e, r) || on();
}
function on() {
  throw new TypeError(`Invalid attempt to destructure non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
}
function br(e, r) {
  if (e) {
    if (typeof e == "string") return qe(e, r);
    var n = Object.prototype.toString.call(e).slice(8, -1);
    if (n === "Object" && e.constructor && (n = e.constructor.name), n === "Map" || n === "Set") return Array.from(e);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return qe(e, r);
  }
}
function qe(e, r) {
  (r == null || r > e.length) && (r = e.length);
  for (var n = 0, t = new Array(r); n < r; n++)
    t[n] = e[n];
  return t;
}
function an(e, r) {
  var n = e == null ? null : typeof Symbol < "u" && e[Symbol.iterator] || e["@@iterator"];
  if (n != null) {
    var t = [], o = !0, a = !1, l, s;
    try {
      for (n = n.call(e); !(o = (l = n.next()).done) && (t.push(l.value), !(r && t.length === r)); o = !0)
        ;
    } catch (O) {
      a = !0, s = O;
    } finally {
      try {
        !o && n.return != null && n.return();
      } finally {
        if (a) throw s;
      }
    }
    return t;
  }
}
function un(e) {
  if (Array.isArray(e)) return e;
}
var cn = "file-invalid-type", sn = "file-too-large", fn = "file-too-small", ln = "too-many-files", pn = function(r) {
  r = Array.isArray(r) && r.length === 1 ? r[0] : r;
  var n = Array.isArray(r) ? "one of ".concat(r.join(", ")) : r;
  return {
    code: cn,
    message: "File type must be ".concat(n)
  };
}, cr = function(r) {
  return {
    code: sn,
    message: "File is larger than ".concat(r, " ").concat(r === 1 ? "byte" : "bytes")
  };
}, sr = function(r) {
  return {
    code: fn,
    message: "File is smaller than ".concat(r, " ").concat(r === 1 ? "byte" : "bytes")
  };
}, dn = {
  code: ln,
  message: "Too many files"
};
function hr(e, r) {
  var n = e.type === "application/x-moz-file" || en(e, r);
  return [n, n ? null : pn(r)];
}
function Or(e, r, n) {
  if (X(e.size))
    if (X(r) && X(n)) {
      if (e.size > n) return [!1, cr(n)];
      if (e.size < r) return [!1, sr(r)];
    } else {
      if (X(r) && e.size < r) return [!1, sr(r)];
      if (X(n) && e.size > n) return [!1, cr(n)];
    }
  return [!0, null];
}
function X(e) {
  return e != null;
}
function vn(e) {
  var r = e.files, n = e.accept, t = e.minSize, o = e.maxSize, a = e.multiple, l = e.maxFiles, s = e.validator;
  return !a && r.length > 1 || a && l >= 1 && r.length > l ? !1 : r.every(function(O) {
    var A = hr(O, n), E = se(A, 1), w = E[0], P = Or(O, t, o), z = se(P, 1), H = z[0], j = s ? s(O) : null;
    return w && H && !j;
  });
}
function Oe(e) {
  return typeof e.isPropagationStopped == "function" ? e.isPropagationStopped() : typeof e.cancelBubble < "u" ? e.cancelBubble : !1;
}
function be(e) {
  return e.dataTransfer ? Array.prototype.some.call(e.dataTransfer.types, function(r) {
    return r === "Files" || r === "application/x-moz-file";
  }) : !!e.target && !!e.target.files;
}
function fr(e) {
  e.preventDefault();
}
function yn(e) {
  return e.indexOf("MSIE") !== -1 || e.indexOf("Trident/") !== -1;
}
function gn(e) {
  return e.indexOf("Edge/") !== -1;
}
function mn() {
  var e = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : window.navigator.userAgent;
  return yn(e) || gn(e);
}
function $() {
  for (var e = arguments.length, r = new Array(e), n = 0; n < e; n++)
    r[n] = arguments[n];
  return function(t) {
    for (var o = arguments.length, a = new Array(o > 1 ? o - 1 : 0), l = 1; l < o; l++)
      a[l - 1] = arguments[l];
    return r.some(function(s) {
      return !Oe(t) && s && s.apply(void 0, [t].concat(a)), Oe(t);
    });
  };
}
function bn() {
  return "showOpenFilePicker" in window;
}
function hn(e) {
  if (X(e)) {
    var r = Object.entries(e).filter(function(n) {
      var t = se(n, 2), o = t[0], a = t[1], l = !0;
      return wr(o) || (console.warn('Skipped "'.concat(o, '" because it is not a valid MIME type. Check https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types for a list of valid MIME types.')), l = !1), (!Array.isArray(a) || !a.every(Dr)) && (console.warn('Skipped "'.concat(o, '" because an invalid file extension was provided.')), l = !1), l;
    }).reduce(function(n, t) {
      var o = se(t, 2), a = o[0], l = o[1];
      return ur(ur({}, n), {}, mr({}, a, l));
    }, {});
    return [{
      // description is required due to https://crbug.com/1264708
      description: "Files",
      accept: r
    }];
  }
  return e;
}
function On(e) {
  if (X(e))
    return Object.entries(e).reduce(function(r, n) {
      var t = se(n, 2), o = t[0], a = t[1];
      return [].concat(or(r), [o], or(a));
    }, []).filter(function(r) {
      return wr(r) || Dr(r);
    }).join(",");
}
function wn(e) {
  return e instanceof DOMException && (e.name === "AbortError" || e.code === e.ABORT_ERR);
}
function Dn(e) {
  return e instanceof DOMException && (e.name === "SecurityError" || e.code === e.SECURITY_ERR);
}
function wr(e) {
  return e === "audio/*" || e === "video/*" || e === "image/*" || e === "text/*" || /\w+\/[-+.\w]+/g.test(e);
}
function Dr(e) {
  return /^.*\.[\w]+$/.test(e);
}
var En = ["children"], Tn = ["open"], An = ["refKey", "role", "onKeyDown", "onFocus", "onBlur", "onClick", "onDragEnter", "onDragOver", "onDragLeave", "onDrop"], jn = ["refKey", "onChange", "onClick"];
function xn(e) {
  return Fn(e) || _n(e) || Er(e) || Pn();
}
function Pn() {
  throw new TypeError(`Invalid attempt to spread non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
}
function _n(e) {
  if (typeof Symbol < "u" && e[Symbol.iterator] != null || e["@@iterator"] != null) return Array.from(e);
}
function Fn(e) {
  if (Array.isArray(e)) return Ke(e);
}
function Le(e, r) {
  return Rn(e) || In(e, r) || Er(e, r) || Sn();
}
function Sn() {
  throw new TypeError(`Invalid attempt to destructure non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
}
function Er(e, r) {
  if (e) {
    if (typeof e == "string") return Ke(e, r);
    var n = Object.prototype.toString.call(e).slice(8, -1);
    if (n === "Object" && e.constructor && (n = e.constructor.name), n === "Map" || n === "Set") return Array.from(e);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return Ke(e, r);
  }
}
function Ke(e, r) {
  (r == null || r > e.length) && (r = e.length);
  for (var n = 0, t = new Array(r); n < r; n++)
    t[n] = e[n];
  return t;
}
function In(e, r) {
  var n = e == null ? null : typeof Symbol < "u" && e[Symbol.iterator] || e["@@iterator"];
  if (n != null) {
    var t = [], o = !0, a = !1, l, s;
    try {
      for (n = n.call(e); !(o = (l = n.next()).done) && (t.push(l.value), !(r && t.length === r)); o = !0)
        ;
    } catch (O) {
      a = !0, s = O;
    } finally {
      try {
        !o && n.return != null && n.return();
      } finally {
        if (a) throw s;
      }
    }
    return t;
  }
}
function Rn(e) {
  if (Array.isArray(e)) return e;
}
function lr(e, r) {
  var n = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var t = Object.getOwnPropertySymbols(e);
    r && (t = t.filter(function(o) {
      return Object.getOwnPropertyDescriptor(e, o).enumerable;
    })), n.push.apply(n, t);
  }
  return n;
}
function x(e) {
  for (var r = 1; r < arguments.length; r++) {
    var n = arguments[r] != null ? arguments[r] : {};
    r % 2 ? lr(Object(n), !0).forEach(function(t) {
      $e(e, t, n[t]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(n)) : lr(Object(n)).forEach(function(t) {
      Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(n, t));
    });
  }
  return e;
}
function $e(e, r, n) {
  return r in e ? Object.defineProperty(e, r, { value: n, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = n, e;
}
function we(e, r) {
  if (e == null) return {};
  var n = Cn(e, r), t, o;
  if (Object.getOwnPropertySymbols) {
    var a = Object.getOwnPropertySymbols(e);
    for (o = 0; o < a.length; o++)
      t = a[o], !(r.indexOf(t) >= 0) && Object.prototype.propertyIsEnumerable.call(e, t) && (n[t] = e[t]);
  }
  return n;
}
function Cn(e, r) {
  if (e == null) return {};
  var n = {}, t = Object.keys(e), o, a;
  for (a = 0; a < t.length; a++)
    o = t[a], !(r.indexOf(o) >= 0) && (n[o] = e[o]);
  return n;
}
var Ue = /* @__PURE__ */ Sr(function(e, r) {
  var n = e.children, t = we(e, En), o = kn(t), a = o.open, l = we(o, Tn);
  return Ir(r, function() {
    return {
      open: a
    };
  }, [a]), /* @__PURE__ */ _r.createElement(Rr, null, n(x(x({}, l), {}, {
    open: a
  })));
});
Ue.displayName = "Dropzone";
var Tr = {
  disabled: !1,
  getFilesFromEvent: Ur,
  maxSize: 1 / 0,
  minSize: 0,
  multiple: !0,
  maxFiles: 0,
  preventDropOnDocument: !0,
  noClick: !1,
  noKeyboard: !1,
  noDrag: !1,
  noDragEventsBubbling: !1,
  validator: null,
  useFsAccessApi: !0,
  autoFocus: !1
};
Ue.defaultProps = Tr;
Ue.propTypes = {
  /**
   * Render function that exposes the dropzone state and prop getter fns
   *
   * @param {object} params
   * @param {Function} params.getRootProps Returns the props you should apply to the root drop container you render
   * @param {Function} params.getInputProps Returns the props you should apply to hidden file input you render
   * @param {Function} params.open Open the native file selection dialog
   * @param {boolean} params.isFocused Dropzone area is in focus
   * @param {boolean} params.isFileDialogActive File dialog is opened
   * @param {boolean} params.isDragActive Active drag is in progress
   * @param {boolean} params.isDragAccept Dragged files are accepted
   * @param {boolean} params.isDragReject Some dragged files are rejected
   * @param {File[]} params.acceptedFiles Accepted files
   * @param {FileRejection[]} params.fileRejections Rejected files and why they were rejected
   */
  children: T.func,
  /**
   * Set accepted file types.
   * Checkout https://developer.mozilla.org/en-US/docs/Web/API/window/showOpenFilePicker types option for more information.
   * Keep in mind that mime type determination is not reliable across platforms. CSV files,
   * for example, are reported as text/plain under macOS but as application/vnd.ms-excel under
   * Windows. In some cases there might not be a mime type set at all (https://github.com/react-dropzone/react-dropzone/issues/276).
   */
  accept: T.objectOf(T.arrayOf(T.string)),
  /**
   * Allow drag 'n' drop (or selection from the file dialog) of multiple files
   */
  multiple: T.bool,
  /**
   * If false, allow dropped items to take over the current browser window
   */
  preventDropOnDocument: T.bool,
  /**
   * If true, disables click to open the native file selection dialog
   */
  noClick: T.bool,
  /**
   * If true, disables SPACE/ENTER to open the native file selection dialog.
   * Note that it also stops tracking the focus state.
   */
  noKeyboard: T.bool,
  /**
   * If true, disables drag 'n' drop
   */
  noDrag: T.bool,
  /**
   * If true, stops drag event propagation to parents
   */
  noDragEventsBubbling: T.bool,
  /**
   * Minimum file size (in bytes)
   */
  minSize: T.number,
  /**
   * Maximum file size (in bytes)
   */
  maxSize: T.number,
  /**
   * Maximum accepted number of files
   * The default value is 0 which means there is no limitation to how many files are accepted.
   */
  maxFiles: T.number,
  /**
   * Enable/disable the dropzone
   */
  disabled: T.bool,
  /**
   * Use this to provide a custom file aggregator
   *
   * @param {(DragEvent|Event)} event A drag event or input change event (if files were selected via the file dialog)
   */
  getFilesFromEvent: T.func,
  /**
   * Cb for when closing the file dialog with no selection
   */
  onFileDialogCancel: T.func,
  /**
   * Cb for when opening the file dialog
   */
  onFileDialogOpen: T.func,
  /**
   * Set to true to use the https://developer.mozilla.org/en-US/docs/Web/API/File_System_Access_API
   * to open the file picker instead of using an `<input type="file">` click event.
   */
  useFsAccessApi: T.bool,
  /**
   * Set to true to focus the root element on render
   */
  autoFocus: T.bool,
  /**
   * Cb for when the `dragenter` event occurs.
   *
   * @param {DragEvent} event
   */
  onDragEnter: T.func,
  /**
   * Cb for when the `dragleave` event occurs
   *
   * @param {DragEvent} event
   */
  onDragLeave: T.func,
  /**
   * Cb for when the `dragover` event occurs
   *
   * @param {DragEvent} event
   */
  onDragOver: T.func,
  /**
   * Cb for when the `drop` event occurs.
   * Note that this callback is invoked after the `getFilesFromEvent` callback is done.
   *
   * Files are accepted or rejected based on the `accept`, `multiple`, `minSize` and `maxSize` props.
   * `accept` must be a valid [MIME type](http://www.iana.org/assignments/media-types/media-types.xhtml) according to [input element specification](https://www.w3.org/wiki/HTML/Elements/input/file) or a valid file extension.
   * If `multiple` is set to false and additional files are dropped,
   * all files besides the first will be rejected.
   * Any file which does not have a size in the [`minSize`, `maxSize`] range, will be rejected as well.
   *
   * Note that the `onDrop` callback will always be invoked regardless if the dropped files were accepted or rejected.
   * If you'd like to react to a specific scenario, use the `onDropAccepted`/`onDropRejected` props.
   *
   * `onDrop` will provide you with an array of [File](https://developer.mozilla.org/en-US/docs/Web/API/File) objects which you can then process and send to a server.
   * For example, with [SuperAgent](https://github.com/visionmedia/superagent) as a http/ajax library:
   *
   * ```js
   * function onDrop(acceptedFiles) {
   *   const req = request.post('/upload')
   *   acceptedFiles.forEach(file => {
   *     req.attach(file.name, file)
   *   })
   *   req.end(callback)
   * }
   * ```
   *
   * @param {File[]} acceptedFiles
   * @param {FileRejection[]} fileRejections
   * @param {(DragEvent|Event)} event A drag event or input change event (if files were selected via the file dialog)
   */
  onDrop: T.func,
  /**
   * Cb for when the `drop` event occurs.
   * Note that if no files are accepted, this callback is not invoked.
   *
   * @param {File[]} files
   * @param {(DragEvent|Event)} event
   */
  onDropAccepted: T.func,
  /**
   * Cb for when the `drop` event occurs.
   * Note that if no files are rejected, this callback is not invoked.
   *
   * @param {FileRejection[]} fileRejections
   * @param {(DragEvent|Event)} event
   */
  onDropRejected: T.func,
  /**
   * Cb for when there's some error from any of the promises.
   *
   * @param {Error} error
   */
  onError: T.func,
  /**
   * Custom validation function. It must return null if there's no errors.
   * @param {File} file
   * @returns {FileError|FileError[]|null}
   */
  validator: T.func
};
var He = {
  isFocused: !1,
  isFileDialogActive: !1,
  isDragActive: !1,
  isDragAccept: !1,
  isDragReject: !1,
  acceptedFiles: [],
  fileRejections: []
};
function kn() {
  var e = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, r = x(x({}, Tr), e), n = r.accept, t = r.disabled, o = r.getFilesFromEvent, a = r.maxSize, l = r.minSize, s = r.multiple, O = r.maxFiles, A = r.onDragEnter, E = r.onDragLeave, w = r.onDragOver, P = r.onDrop, z = r.onDropAccepted, H = r.onDropRejected, j = r.onFileDialogCancel, C = r.onFileDialogOpen, q = r.useFsAccessApi, le = r.autoFocus, ne = r.preventDropOnDocument, pe = r.noClick, te = r.noKeyboard, de = r.noDrag, B = r.noDragEventsBubbling, ie = r.onError, G = r.validator, J = N(function() {
    return On(n);
  }, [n]), oe = N(function() {
    return hn(n);
  }, [n]), ae = N(function() {
    return typeof C == "function" ? C : pr;
  }, [C]), Q = N(function() {
    return typeof j == "function" ? j : pr;
  }, [j]), F = me(null), L = me(null), K = Fr(zn, He), Y = Le(K, 2), ue = Y[0], k = Y[1], i = ue.isFocused, c = ue.isFileDialogActive, v = me(typeof window < "u" && window.isSecureContext && q && bn()), y = function() {
    !v.current && c && setTimeout(function() {
      if (L.current) {
        var f = L.current.files;
        f.length || (k({
          type: "closeDialog"
        }), Q());
      }
    }, 300);
  };
  Fe(function() {
    return window.addEventListener("focus", y, !1), function() {
      window.removeEventListener("focus", y, !1);
    };
  }, [L, c, Q, v]);
  var p = me([]), b = function(f) {
    F.current && F.current.contains(f.target) || (f.preventDefault(), p.current = []);
  };
  Fe(function() {
    return ne && (document.addEventListener("dragover", fr, !1), document.addEventListener("drop", b, !1)), function() {
      ne && (document.removeEventListener("dragover", fr), document.removeEventListener("drop", b));
    };
  }, [F, ne]), Fe(function() {
    return !t && le && F.current && F.current.focus(), function() {
    };
  }, [F, le, t]);
  var d = W(function(u) {
    ie ? ie(u) : console.error(u);
  }, [ie]), g = W(function(u) {
    u.preventDefault(), u.persist(), ye(u), p.current = [].concat(xn(p.current), [u.target]), be(u) && Promise.resolve(o(u)).then(function(f) {
      if (!(Oe(u) && !B)) {
        var S = f.length, R = S > 0 && vn({
          files: f,
          accept: J,
          minSize: l,
          maxSize: a,
          multiple: s,
          maxFiles: O,
          validator: G
        }), M = S > 0 && !R;
        k({
          isDragAccept: R,
          isDragReject: M,
          isDragActive: !0,
          type: "setDraggedFiles"
        }), A && A(u);
      }
    }).catch(function(f) {
      return d(f);
    });
  }, [o, A, d, B, J, l, a, s, O, G]), h = W(function(u) {
    u.preventDefault(), u.persist(), ye(u);
    var f = be(u);
    if (f && u.dataTransfer)
      try {
        u.dataTransfer.dropEffect = "copy";
      } catch {
      }
    return f && w && w(u), !1;
  }, [w, B]), D = W(function(u) {
    u.preventDefault(), u.persist(), ye(u);
    var f = p.current.filter(function(R) {
      return F.current && F.current.contains(R);
    }), S = f.indexOf(u.target);
    S !== -1 && f.splice(S, 1), p.current = f, !(f.length > 0) && (k({
      type: "setDraggedFiles",
      isDragActive: !1,
      isDragAccept: !1,
      isDragReject: !1
    }), be(u) && E && E(u));
  }, [F, E, B]), m = W(function(u, f) {
    var S = [], R = [];
    u.forEach(function(M) {
      var ce = hr(M, J), Z = Le(ce, 2), Ee = Z[0], Te = Z[1], Ae = Or(M, l, a), ge = Le(Ae, 2), je = ge[0], xe = ge[1], Pe = G ? G(M) : null;
      if (Ee && je && !Pe)
        S.push(M);
      else {
        var _e = [Te, xe];
        Pe && (_e = _e.concat(Pe)), R.push({
          file: M,
          errors: _e.filter(function(Pr) {
            return Pr;
          })
        });
      }
    }), (!s && S.length > 1 || s && O >= 1 && S.length > O) && (S.forEach(function(M) {
      R.push({
        file: M,
        errors: [dn]
      });
    }), S.splice(0)), k({
      acceptedFiles: S,
      fileRejections: R,
      type: "setFiles"
    }), P && P(S, R, f), R.length > 0 && H && H(R, f), S.length > 0 && z && z(S, f);
  }, [k, s, J, l, a, O, P, z, H, G]), I = W(function(u) {
    u.preventDefault(), u.persist(), ye(u), p.current = [], be(u) && Promise.resolve(o(u)).then(function(f) {
      Oe(u) && !B || m(f, u);
    }).catch(function(f) {
      return d(f);
    }), k({
      type: "reset"
    });
  }, [o, m, d, B]), _ = W(function() {
    if (v.current) {
      k({
        type: "openDialog"
      }), ae();
      var u = {
        multiple: s,
        types: oe
      };
      window.showOpenFilePicker(u).then(function(f) {
        return o(f);
      }).then(function(f) {
        m(f, null), k({
          type: "closeDialog"
        });
      }).catch(function(f) {
        wn(f) ? (Q(f), k({
          type: "closeDialog"
        })) : Dn(f) ? (v.current = !1, L.current ? (L.current.value = null, L.current.click()) : d(new Error("Cannot open the file picker because the https://developer.mozilla.org/en-US/docs/Web/API/File_System_Access_API is not supported and no <input> was provided."))) : d(f);
      });
      return;
    }
    L.current && (k({
      type: "openDialog"
    }), ae(), L.current.value = null, L.current.click());
  }, [k, ae, Q, q, m, d, oe, s]), U = W(function(u) {
    !F.current || !F.current.isEqualNode(u.target) || (u.key === " " || u.key === "Enter" || u.keyCode === 32 || u.keyCode === 13) && (u.preventDefault(), _());
  }, [F, _]), Ye = W(function() {
    k({
      type: "focus"
    });
  }, []), Ge = W(function() {
    k({
      type: "blur"
    });
  }, []), Je = W(function() {
    pe || (mn() ? setTimeout(_, 0) : _());
  }, [pe, _]), V = function(f) {
    return t ? null : f;
  }, De = function(f) {
    return te ? null : V(f);
  }, ve = function(f) {
    return de ? null : V(f);
  }, ye = function(f) {
    B && f.stopPropagation();
  }, Ar = N(function() {
    return function() {
      var u = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, f = u.refKey, S = f === void 0 ? "ref" : f, R = u.role, M = u.onKeyDown, ce = u.onFocus, Z = u.onBlur, Ee = u.onClick, Te = u.onDragEnter, Ae = u.onDragOver, ge = u.onDragLeave, je = u.onDrop, xe = we(u, An);
      return x(x($e({
        onKeyDown: De($(M, U)),
        onFocus: De($(ce, Ye)),
        onBlur: De($(Z, Ge)),
        onClick: V($(Ee, Je)),
        onDragEnter: ve($(Te, g)),
        onDragOver: ve($(Ae, h)),
        onDragLeave: ve($(ge, D)),
        onDrop: ve($(je, I)),
        role: typeof R == "string" && R !== "" ? R : "presentation"
      }, S, F), !t && !te ? {
        tabIndex: 0
      } : {}), xe);
    };
  }, [F, U, Ye, Ge, Je, g, h, D, I, te, de, t]), jr = W(function(u) {
    u.stopPropagation();
  }, []), xr = N(function() {
    return function() {
      var u = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, f = u.refKey, S = f === void 0 ? "ref" : f, R = u.onChange, M = u.onClick, ce = we(u, jn), Z = $e({
        accept: J,
        multiple: s,
        type: "file",
        style: {
          display: "none"
        },
        onChange: V($(R, I)),
        onClick: V($(M, jr)),
        tabIndex: -1
      }, S, L);
      return x(x({}, Z), ce);
    };
  }, [L, n, s, I, t]);
  return x(x({}, ue), {}, {
    isFocused: i && !t,
    getRootProps: Ar,
    getInputProps: xr,
    rootRef: F,
    inputRef: L,
    open: V(_)
  });
}
function zn(e, r) {
  switch (r.type) {
    case "focus":
      return x(x({}, e), {}, {
        isFocused: !0
      });
    case "blur":
      return x(x({}, e), {}, {
        isFocused: !1
      });
    case "openDialog":
      return x(x({}, He), {}, {
        isFileDialogActive: !0
      });
    case "closeDialog":
      return x(x({}, e), {}, {
        isFileDialogActive: !1
      });
    case "setDraggedFiles":
      return x(x({}, e), {}, {
        isDragActive: r.isDragActive,
        isDragAccept: r.isDragAccept,
        isDragReject: r.isDragReject
      });
    case "setFiles":
      return x(x({}, e), {}, {
        acceptedFiles: r.acceptedFiles,
        fileRejections: r.fileRejections
      });
    case "reset":
      return x({}, He);
    default:
      return e;
  }
}
function pr() {
}
export {
  kn as u
};
